import React, {useContext} from 'react'
import {graphql, Link} from 'gatsby'
import SEO from '../components/seo/SEO'
// import Cta from '../components/Cta'
import {WrapperContext} from '../components/Layout'
import ZonesNavCarousel from '../components/ZonesNavCarousel'

export const pageQuery = graphql`
  query ZonesQuery($locale: String!) {
    page: sanityPage(template: {eq: "zones"}, locale: {eq: $locale}) {
      ...page
    }
  }
`

const Zones = ({data: {page}}) => {
  const _WrapperContext = useContext(WrapperContext)
  const {zones} = _WrapperContext
  // console.log(zones)

  const {
    title,
    // texte,
    seo
  } = page

  return (
    <div className='zones'>
      <SEO
        pageTitle={title}
        pageDescription={seo.description}
        template='template-zones'
        pageBanner={seo.image.asset.url}
        page
        // pathname={location.pathname}
      />

      {/* <ZonesNavCarousel input={zones} /> */}

    </div>
  )
}

export default Zones
